// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  one: path(ROOTS_DASHBOARD, '/one'),
  two: path(ROOTS_DASHBOARD, '/two'),
  three: path(ROOTS_DASHBOARD, '/three'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    four: path(ROOTS_DASHBOARD, '/user/four'),
    five: path(ROOTS_DASHBOARD, '/user/five'),
    six: path(ROOTS_DASHBOARD, '/user/six'),
  },
  dashboard: {
    root: path(ROOTS_DASHBOARD, '/dashboard'),
  },
  vendors: {
    root: path(ROOTS_DASHBOARD, '/vendors'),
    create: path(ROOTS_DASHBOARD, '/vendors/create'),
    update: (id) => path(ROOTS_DASHBOARD, `/vendors/update/${id}`),
  },
  ticket_orders: {
    root: path(ROOTS_DASHBOARD, '/ticket-orders'),
  },
  invoices: {
    root: path(ROOTS_DASHBOARD, '/invoices'),
    create: path(ROOTS_DASHBOARD, '/invoices/create'),
    customer: path(ROOTS_DASHBOARD, '/invoices/customerinvoice'),
  },
  businessTags: {
    root: path(ROOTS_DASHBOARD, '/business-tags'),
    create: path(ROOTS_DASHBOARD, '/business-tags/create'),
    edit: (name) => path(ROOTS_DASHBOARD, `/business-tags/edit/${name}`),
  },

  packages: {
    root: path(ROOTS_DASHBOARD, '/packages'),
  },
  eventTags: {
    root: path(ROOTS_DASHBOARD, '/event-tags'),
    create: path(ROOTS_DASHBOARD, '/event-tags/create'),
    edit: (name) => path(ROOTS_DASHBOARD, `/event-tags/edit/${name}`),
  },
  sponsoredEvents: {
    root: path(ROOTS_DASHBOARD, '/sponsored-events'),
    create: path(ROOTS_DASHBOARD, '/sponsored-events/create'),
  },
  groupEvents: {
    root: path(ROOTS_DASHBOARD, '/group-events'),
    create: path(ROOTS_DASHBOARD, '/group-events/create'),
    edit: (name) => path(ROOTS_DASHBOARD, `/group-events/edit/${name}`),
  },
  cancelTickets: {
    root: path(ROOTS_DASHBOARD, '/cancel-tickets'),
    create: path(ROOTS_DASHBOARD, '/cancel-tickets/create'),
  },
  customer: {
    root: path(ROOTS_DASHBOARD, '/customer'),
  },
  campaignPackage: {
    root: path(ROOTS_DASHBOARD, '/campaign/package'),
    create: path(ROOTS_DASHBOARD, '/campaign/package/create'),
    edit: (name) => path(ROOTS_DASHBOARD, `/campaign/package/edit/${name}`),
  },
  campaignCredits: {
    root: path(ROOTS_DASHBOARD, '/campaign/credits'),
    add: path(ROOTS_DASHBOARD, '/campaign/credits/add'),
    edit: (name) => path(ROOTS_DASHBOARD, `/campaign/credits/edit/${name}`),
  },
  campaignTemplate: {
    root: path(ROOTS_DASHBOARD, '/campaign/template'),
    add: path(ROOTS_DASHBOARD, '/campaign/template/add'),
  },
  maintenance: {
    root: path(ROOTS_DASHBOARD, '/maintenance'),
  },

  events: {
    root: path(ROOTS_DASHBOARD, '/events'),
    add: path(ROOTS_DASHBOARD, '/event-record/:id'),
  },
};
