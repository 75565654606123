import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));
export const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));
export const VendorsList = Loadable(lazy(() => import('../pages/vendors/VendorsList')));
export const CreateEditVendor = Loadable(lazy(() => import('../pages/vendors/CreateEditVendor')));
export const UpdateVendor = Loadable(lazy(() => import('../pages/vendors/UpdateVendor')));
export const BusinessTagsList = Loadable(
  lazy(() => import('../pages/business-tags/BusinessTagsList'))
);
export const CreateEditBusinessTags = Loadable(
  lazy(() => import('../pages/business-tags/CreateEditBusinessTags'))
);
export const PackagesList = Loadable(lazy(() => import('../pages/packages/PackagesList')));
export const CreateEditpackages = Loadable(
  lazy(() => import('../pages/packages/CreateEditPackages'))
);
export const EventTagsList = Loadable(lazy(() => import('../pages/events-tags/EventTagsList')));
export const CreateEditEventTags = Loadable(
  lazy(() => import('../pages/events-tags/CreateEditEventTags'))
);
export const CancelTickets = Loadable(lazy(() => import('../pages/cancel-tickets/CancelTickets')));
export const AddCancelTickets = Loadable(
  lazy(() => import('../pages/cancel-tickets/AddCancelTicket'))
);
export const TicketOrders = Loadable(lazy(() => import('../pages/ticket-orders/index')));
export const SponsoredEvents = Loadable(
  lazy(() => import('../pages/sponsored-events/SponsoredEventsList'))
);

export const GroupEvents = Loadable(lazy(() => import('../pages/events-group/GroupList')));
export const CreateGroupEvents = Loadable(lazy(() => import('../pages/events-group/CreateGroup')));
export const Customer = Loadable(lazy(() => import('../pages/customer/Customer')));
export const Events = Loadable(lazy(() => import('../pages/events/Dashboard')));
export const Invoices = Loadable(lazy(() => import('../pages/invoices/Invoices')));
export const CreateInvoice = Loadable(lazy(() => import('../pages/invoices/CreateInvoice')));
export const CustomerInvoice = Loadable(lazy(() => import('../pages/invoices/CustomerInvoice')));

export const CreditsPackageList = Loadable(
  lazy(() => import('../pages/campaign/credits-package/CreditsPackageList'))
);
export const CreateEditCreditPackage = Loadable(
  lazy(() => import('../pages/campaign/credits-package/CreateEditCreditPackage'))
);
export const AddCreditsList = Loadable(
  lazy(() => import('../pages/campaign/add-credits/AddCreditsList'))
);
export const AddCampaignCredits = Loadable(
  lazy(() => import('../pages/campaign/add-credits/AddCampaignCredits'))
);
export const AddTemplateList = Loadable(
  lazy(() => import('../pages/campaign/add-template/AddTemplateList'))
);
export const AddCampaignTemplate = Loadable(
  lazy(() => import('../pages/campaign/add-template/AddCampaignTemplate'))
);
export const Maintenance = Loadable(lazy(() => import('../pages/maintenance')));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));

export const  EventRecords = Loadable(lazy(()=> import("../pages/events/event-records")))